import React from 'react'

export default function Navbar(props) {
  return (
    <div className="navbar navbar-custom navbar-fixed-top navbar-nav">
      <div className="container">
        <Brand url={props.url} white={props.name.white} blue={props.name.blue} />
        <nav className="navbar-collapse collapse">
          <div className="pull-right">
            <Links links={props.links} />
          </div>
        </nav>
      </div>
    </div>
  )
}

function Brand(props) {
  return (
    <div className="navbar-brand">
      <a href={props.url} className="nav-item btn-text">
        <span className="white">{props.white}</span> {props.blue}
      </a>
    </div>
  )
}

function Links(props) {
  if (!props.links) return null

  return props.links.map(link => {
    return (
      <a href={link.url} key={link.url} className="nav-item btn-text">
        <span className="white">{link.name}</span>
      </a>
    )
  })
}
