import React from 'react'

export default function Footer() {
  return (
    <footer className="footer">
      <div className="container" role="footer">
        Copyright &copy; 2018 Knewton. All rights reserved.
        <a href="/terms" className="link pull-right">
          Terms
        </a>
        <a href="/privacy" className="link pull-right">
          Privacy
        </a>
      </div>
    </footer>
  )
}
